
.form-field input {
  height: 2rem;
  font-size: 1rem;
}
.form-field .input_chips {
  all: unset;
}
.chips_contianer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  border: 1px solid #cecece;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: text;
  align-items: center;

}
.chips_contianer .chips_list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.chips_contianer .chips_list .chip {
  background-color: #00000014;
  padding: 4px 8px;
  border-radius: 2px;
  width: max-content;
}
.chips_contianer .chips_list .chip span {
  /* font-size: large; */
  cursor: pointer;
  padding-left: 4px;
}
.chips_contianer .chips_list .chip span:hover {
  font-weight: bold;
}
